/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { MESSAGE_TYPES, sendMessageToReactNative } from './app/rn-messages';
import { hardCodeTargetSelfInWindowOpen, decideWhetherToOpenLinksExternally } from './links';
import { isIos, isAndroid, isApp } from './native-app/app-utils';
import { initServiceWorkerRegistration } from './native-app/service-worker-reg';
import { useNativeGoogleLoginForApp, useNativeFacebookLoginForApp } from './social-logins';
import { domReady, show } from './utils';

// Initialize the service worker, whether native app or not (we need it in both cases)
initServiceWorkerRegistration();

const attachFunctionalityToLoadedPage = () => {
  // Handle edge case where this function is called where MorphMarket state is not yet defined.
  // This shouldn't happen, but if it does, we don't want to throw an error because this would
  // prevent the later code from executing.
  if (window.MorphMarket?.state) {
    // We want to make sure we don't attach this functionality more than once. Otherwise
    // things become inefficient because the same event handlers might run multiple times.
    window.MorphMarket.state.attachedFunctionalityToPage = true;
  }

  useNativeFacebookLoginForApp();
  useNativeGoogleLoginForApp();

  hardCodeTargetSelfInWindowOpen();
  decideWhetherToOpenLinksExternally();
  showButtonsToDownloadApps();
  makeOpenInBrowserLinksWork();
};

const makeOpenInBrowserLinksWork = () => {
  document.querySelectorAll('[data-open-in-browser]').forEach((el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      const url = el.getAttribute('href');
      sendMessageToReactNative(MESSAGE_TYPES.OPEN_LINK_IN_BROWSER, { url });
    });
  });
};

// This function needs to handle two sources of initialization (otherwise some pages will never get their code
// initialized)
//
// 1. When Django serves a page (which itself will give a `load` event).
// 2. When the React Router displays a page (which will NOT give a `load` event but instead will give a `loadedPage` event)
//
// Careful: All dispatchers and listeners for the custom event should be on the window object (both here and within the app)
const initializeCodeOncePageLoads = () => {
  const eventName = 'loadedPage';
  window.addEventListener(eventName, attachFunctionalityToLoadedPage);

  domReady(() => {
    // We use the load event to trigger the "loadedPage" event (instead of attaching
    // the functionality here) because doing so simplifies the code in the app. Specifically,
    // it means the app only has to worry about a single event ("loadedPage") rather than two
    // events "loadedPage" or "load".

    const event = new window.Event(eventName);
    window.dispatchEvent(event);
  });
};

initializeCodeOncePageLoads();

// This code should only execute for installed app. This code is not in the jQuery block above b/c we are using VanillaJS here
// This currently only triggers on the home-page.
//
// It does not do anything when the website is displayed on desktop (i.e. non-phone)
function showButtonsToDownloadApps() {
  if (isApp()) {
    return;
  }

  domReady(() => {
    // We can test this function in Chrome Dev Tools by selecting iPhone from the responsive options
    if (isIos()) {
      show(document.getElementById('ios-app-install-btn'));
    } else if (isAndroid()) {
      show(document.getElementById('android-app-install-btn'));
    }
  });
}
