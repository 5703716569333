// One of the strategies taken here is to override the original `window.open` function such that the target
// parameter is always "self" (instead of potentially _blank). Before doing so, we need to get a
// reference to the original `window.open` function. Therefore it's important this be declared before `hardCodeTargetSelfInWindowOpen`
export const originalWindowOpen = window.open;
//
// The new function behaves the same as the overridden one in all other ways.
//
// We need this in the app to prevent certain links opening up in an external browser.
export const hardCodeTargetSelfInWindowOpen = () => {
  window.open = (url, _target, windowFeatures) => originalWindowOpen(url, '_self', windowFeatures);
};

// This function uses something called Event Delegation (https://davidwalsh.name/event-delegate)
// in order to modify the `target` attribute not just from links that exist when the page first loads,
// but also from links added later (e.g. via dynamically added content in React).
//
// We need this in the app to prevent certain links opening up in an external browser.
//
// We can override this behavior by setting the data-open-in-same-tab attribute on the link.
export const decideWhetherToOpenLinksExternally = () => {
  document.addEventListener('click', (event) => {
    let link;
    const clickedEl = event.target;

    // Sometimes the HTML contains <a> elements that wrap <divs>s or <imgs>s.
    // Therefore we need to traverse up the element tree and find the
    // nearest "a" element, since that's the one that will have the
    // target attribute;
    if (clickedEl.tagName === 'A') {
      link = clickedEl;
    } else {
      link = clickedEl.closest('a');
    }

    // In some cases clicks land on SVGs and DIVs in the React app
    // that are only responsible for changing on-page content. We
    // ignore these.
    if (!link) {
      return;
    }

    // If a link has the data-open-in-same-tab attribute, return early.
    if (link.dataset.openInSameTab) {
      return;
    }

    // For links to external URLs, we generally want to open these in an external browser rather
    // than in the app.
    //
    // We should only apply this behavior links on the MorphMarket website (relative and absolute paths).
    // This needs to work on all our staging/dev environments, so a regex approach seemed impractical.
    if (link.origin !== window.location.origin) {
      event.preventDefault();
      // We need to use `originalWindowOpen` because we hard-coded `window.open` to always set `target=_self`
      try {
        originalWindowOpen(link.href, '_blank');
      } catch (e) {
        // Do nothing
        // eslint-disable-next-line no-console
        console.warn('Invalid link href', link.href);
      }

      return;
    }

    // We aren't interested in elements without target attributes set.
    if (!link.attributes.target) {
      return;
    }

    event.preventDefault();
    window.open(link.href, '_self');
  });
};
