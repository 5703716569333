import { Workbox } from 'workbox-window';
import { reloadPageWithAddedDebugCode } from '../utils';

let wb = null;

export const getWorkboxWindowSwInstance = () => wb;

export const initServiceWorkerRegistration = async () => {
  // Service worker registration
  // from Workbox advanced recipes - https://developers.google.com/web/tools/workbox/guides/advanced-recipes
  if ('serviceWorker' in navigator) {
    wb = new Workbox('/sw.js');
    let registration;

    const showSkipWaitingPrompt = (event) => {
      const refreshPageAndSkipWaiting = () => {
        // Set up a listener that will reload the page as soon as the previously waiting service worker has taken control.
        wb.addEventListener('controlling', () => {
          reloadPageWithAddedDebugCode('service_worker__controlling_v2');
        });

        if ((registration && registration.waiting) || event.type === 'waiting') {
          // Send a message to the waiting service worker, instructing it to activate.
          // Note: there is a "SKIP_WAITING" message listener in the service worker that responsds and skips waiting
          // which will then cause the "controlling" listener to fire above
          wb.messageSW({ type: 'SKIP_WAITING' });
        }
      };
      // `event.wasWaitingBeforeRegister` will be false if this is
      // the first time the updated service worker is waiting.
      // When `event.wasWaitingBeforeRegister` is true, a previously
      // updated service worker is still waiting.
      //
      // This will cause an extra reload of the page but is required to finish install of a newly downloaded service worker
      refreshPageAndSkipWaiting();
    };

    // Add an event listener to detect when the registered
    // service worker has installed but is waiting to activate.
    wb.addEventListener('waiting', showSkipWaitingPrompt);
    wb.addEventListener('externalwaiting', showSkipWaitingPrompt);

    wb.register();
  }
};
