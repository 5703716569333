import { MESSAGE_TYPES, sendMessageToReactNative } from './app/rn-messages';
import { isApp } from './native-app/app-utils';

// See social-logins.md to understand what this is for.
export const useNativeFacebookLoginForApp = () => {
  const facebookLoginLink = document.querySelector('a[title=Facebook]');

  if (facebookLoginLink && isApp()) {
    facebookLoginLink.href = '';
    facebookLoginLink.onclick = (event) => {
      event.preventDefault();
      sendMessageToReactNative(MESSAGE_TYPES.USER_WANTS_TO_LOGIN_WITH_FACEBOOK);
    };
  }
};

// See social-logins.md to understand what this is for.
export const useNativeGoogleLoginForApp = () => {
  const googleLoginLink = document.querySelector('a[title=Google]');

  if (googleLoginLink && isApp()) {
    googleLoginLink.href = '';
    googleLoginLink.onclick = (event) => {
      event.preventDefault();
      sendMessageToReactNative(MESSAGE_TYPES.USER_WANTS_TO_LOGIN_WITH_GOOGLE);
    };
  }
};
